#hero-section{
    background-image: url('../../../public/images/hero.jpg');
    background-position: center !important;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    justify-content: space-between;
    min-height: 550px;
    position: relative;
    text-align: left;
    color: #fff;
}
.logo{
    padding: 0 0 120px 0;
    /* margin-left: 80px; */
    margin-left: 55px;
    margin-right: 35px;
    width: 150px;
}
.logo img{
    width: 100%;
}
.tagline-with-steps{
    margin-left: 80px;
    margin-right: 35px;
}
.tagline1{
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    font-family: Arial, Helvetica, sans-serif;
    width: 50%;
}
.tagline2{
    width: 50%;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin-top: 24px;
}
.steps-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
}
.steps-item{
    display: flex;
    align-items: center;
    padding-right: 16px;
}
.step-icon{
    background-color: rgb(184, 184, 184);
    height: 25px;
    width: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    color: #000;
}
@media(max-width:1140px){
    .steps-container{
        width: 50%;
    }
}
@media (max-width:950px) {
    #hero-section{
        background-image: url('../../../public/images/hero_tablet_landscape.jpg');
        height: 100%;
        padding: 30px 0;
    }
    .steps-container{
        display: none;
    }
    .tagline-with-steps{
        margin: 0 30px;
    }
    .logo{
        margin-left: 20px;
        margin-right: 20px;
        width: 100px;
        padding-bottom: 0;
    }
    .tagline1, .tagline2{
        width: 100%;
    }
    .tagline1{
        font-size: 32px;
    }
    .tagline2{
        font-size: 16px;
    }
}