@media (max-width: 1366px) and (min-width: 1201px) {
    .footer-container {
        width: calc(100% - 520px);
    }
}
@media (max-width: 1366px) and (min-width: 1201px) {
    .page-footer {
        margin-left: 80px;
        margin-right: 35px;
    }
}
.page-footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    margin-top: 72px;
    margin-left: 80px;
    margin-right: 35px;
}

.powered-by {
    color: #333;
    font-size: 12px;
    line-height: 20px;
}
.copyright {
    color: #333;
    font-size: 12px;
    line-height: 20px;
}
.tos{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    font-size: 12px;
    font-weight: 700;
}
.transcend-link-wrapper{
    display: flex;
    align-items: center;
}
.tos-item{
    color: #666;
    text-decoration: none;
}
@media (max-width: 1024px) {
    .page-footer {
        align-items: center;
        margin-bottom: 72px;
        margin-top: 40px;
    }
}
@media (max-width: 1024px) {
    .page-footer {
        margin-left: 24px;
        margin-right: 24px;
    }
}